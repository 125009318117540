import { createStore } from 'vuex'

export default createStore({
  state: {
    webname: 'RICHMAN711',
    img_bg: '',
    logoweb: '',
    social_line: 'https://line.me/ti/p/@',
    token: sessionStorage.getItem('token'),
    IP: 'api.ttt-project.com',
    // IP: '192.168.1.53',
    apiname: '',
    API: '',
    phonenumber: '',
    pin: '',
    msgRetrun: '',
    team_ref: '',
    bankdeposit: [],
    bankmember: [],
    bankName: '',
    bankaccount: '',
    bankNameth: 'กรุณาเลือกธนาคาร...',
    bankid: '',
    fname: '',
    lname: '',
    imgbank: '',
    chanel: '62aca157b4839cabb5622db4',
    statusmem: '',
    password: '',
    idline: '',
    captcha: '',
    username: '',
    user_id: '',
    createdate: '-',
    credit: '0',
    privilege: '',
    gametype: '',
    gamename: '',
    gamelink: '',
    captchaID: '',
    imgcaptcha: '',
    withdraw_count: 0,
    agent_id: '',
    promotion_id: '',
    promotion_name: '',
    promotion_status: '',
    promotion_datail: '',
    promotion_image: '',
    partner_type: '',
    partner_ref: '',
    partner_name: '',
    minigame: '',
    ticket: false,
    ticket_limit_day: false,
    list_ticket_minigame: [],
    reward_ticket_minigame: '',
    status_cooldown: false,
    time_cooldown: 0,
    status_deposit_minigame: false,
  },
  getters: {
    templete: (state) => state.templete,
    img_bg: (state) => state.img_bg,
    webname: (state) => state.webname,
    logoweb: (state) => state.logoweb,
    ocial_line: (state) => state.social_line,
    // social_line: (state) => state.social_line + state.webname,
    token: (state) => state.token,
    IP: (state) => state.IP,
    team_ref: (state) => state.team_ref,
    password: (state) => state.password,
    API: (state) => state.API,
    phonenumber: (state) => state.phonenumber,
    pin: (state) => state.pin,
    bankdeposit: (state) => state.bankdeposit,
    bankmember: (state) => state.bankmember,
    bankName: (state) => state.bankName,
    bankNameth: (state) => state.bankNameth,
    bankid: (state) => state.bankid,
    bankaccount: (state) => state.bankaccount,
    imgbank: (state) => state.imgbank,
    fname: (state) => state.fname,
    lname: (state) => state.lname,
    name: (state) => state.fname + '  ' + state.lname,
    chanel: (state) => state.chanel,
    idline: (state) => state.idline,
    captcha: (state) => state.captcha,
    username: (state) => state.username,
    user_id: (state) => state.user_id,
    statusmem: (state) => state.statusmem,
    createdate: (state) => state.createdate,
    credit: (state) => state.credit,
    privilege: (state) => state.privilege,
    gametype: (state) => state.gametype,
    gamename: (state) => state.gamename,
    gamelink: (state) => state.gamelink,
    captchaID: (state) => state.captchaID,
    imgcaptcha: (state) => state.imgcaptcha,
    withdraw_count: (state) => state.withdraw_count,
    agent_id: (state) => state.agent_id,
    promotion_id: (state) => state.promotion_id,
    promotion_name: (state) => state.promotion_name,
    promotion_status: (state) => state.promotion_status,
    promotion_detail: (state) => state.promotion_datail,
    promotion_image: (state) => state.promotion_image,
    partner_type: (state) => state.partner_type,
    partner_ref: (state) => state.partner_ref,
    partner_name: (state) => state.partner_name,
    ticket: (state) => state.ticket,
    ticket_limit_day: (state) => state.ticket_limit_day,
    list_ticket_minigame: (state) => state.list_ticket_minigame,
    reward_ticket_minigame: (state) => state.reward_ticket_minigame,
    status_cooldown: (state) => state.status_cooldown,
    time_cooldown: (state) => state.time_cooldown,
    minigame: (state) => state.minigame,
    status_deposit_minigame: (state) => state.status_deposit_minigame,
  },
  mutations: {
    preparevalue(state) {
      state.gamelink = ''
      state.gametype = ''
      state.token = sessionStorage.getItem('token')
    },
    clearall(state) {
      state.team_ref = ''
      state.phonenumber = ''
      state.pin = ''
      state.password = ''
      state.bankdeposit = []
      state.bankmember = []
      state.bankName = ''
      state.bankNameth = 'กรุณาเลือกธนาคาร...'
      state.bankid = ''
      state.bankaccount = ''
      state.imgbank = ''
      state.fname = ''
      state.lname = ''
      state.password = ''
      state.chanel = '62aca157b4839cabb5622db4'
      state.idline = ''
      state.captcha = ''
      state.username = ''
      state.user_id = ''
      state.statusmem = ''
      state.createdate = '-'
      state.credit = ''
      state.privilege = ''
      state.gametype = ''
      state.gamelink = ''
      state.withdraw_count = 0
      state.promotion_id = ''
      state.promotion_name = ''
      state.promotion_status = ''
      state.promotion_datail = ''
      state.promotion_image = ''
      state.minigame = ''
      state.list_ticket_minigame = []
      state.reward_ticket_minigame = ''
    },
    setlogoweb(state, logoweb) {
      state.logoweb = logoweb
    },
    setimg_bg(state, img_bg) {
      state.img_bg = img_bg
    },
    setteam_ref(state, team_ref) {
      state.team_ref = team_ref
    },
    setphonenumber(state, phonenumber) {
      state.phonenumber = phonenumber
    },
    setpin(state, pin) {
      state.pin = pin
    },
    settoken(state, token) {
      state.token = token
    },
    setbkdp(state, bankdeposit) {
      state.bankdeposit = bankdeposit
    },
    setbkmb(state, bankmember) {
      state.bankmember = bankmember
    },
    setbkname(state, bankName) {
      state.bankName = bankName
    },
    setbkacc(state, bankaccount) {
      state.bankaccount = bankaccount
    },
    setbknameth(state, bankNameth) {
      state.bankNameth = bankNameth
    },
    setimgbk(state, imgbank) {
      state.imgbank = imgbank
    },
    setbkid(state, bankid) {
      state.bankid = bankid
    },
    setfname(state, fname) {
      state.fname = fname
    },
    setlname(state, lname) {
      state.lname = lname
    },
    setchanel(state, chanel) {
      state.chanel = chanel
    },
    setpassword(state, password) {
      state.password = password
    },
    setidline(state, idline) {
      state.idline = idline
    },
    setcaptcha(state, captcha) {
      state.captcha = captcha
    },
    setcaptchaID(state, captchaID) {
      state.captchaID = captchaID
    },
    setstatusmem(state, statusmem) {
      state.statusmem = statusmem
    },
    setimgcaptcha(state, imgcaptcha) {
      state.imgcaptcha = imgcaptcha
    },
    setusername(state, username) {
      state.username = username
    },
    setuser_id(state, user_id) {
      state.user_id = user_id
    },
    setcreatedate(state, createdate) {
      state.createdate = createdate
    },
    setcredit(state, credit) {
      state.credit = credit
    },
    setprivilege(state, privilege) {
      state.privilege = privilege
    },
    setgametype(state, gametype) {
      state.gametype = gametype
    },
    setgamelink(state, gamelink) {
      state.gamelink = gamelink
    },
    setgamename(state, gamename) {
      state.gamename = gamename
    },
    setwdc(state, withdraw_count) {
      state.withdraw_count = withdraw_count
    },
    setagentid(state, agent_id) {
      state.agent_id = agent_id
    },
    setpromotion(state, promotion) {
      state.promotion_id = promotion._id
      state.promotion_name = promotion.name
      state.promotion_status = promotion.status
      state.promotion_datail = promotion.detail
      state.promotion_image = promotion.image
    },
    setpartner(state, partner) {
      state.partner_type = partner.partner_type
      state.partner_ref = partner.partner_ref
    },
    setpartnername(state, partner_name) {
      state.partner_name = partner_name
    },
    setticket(state, ticket) {
      state.ticket = ticket
    },
    setticket_limit_day(state, ticket_limit_day) {
      state.ticket_limit_day = ticket_limit_day
    },
    setlist_ticket_minigame(state, list_ticket_minigame) {
      state.list_ticket_minigame = list_ticket_minigame
    },
    setreward_ticket_minigame(state, reward_ticket_minigame) {
      state.reward_ticket_minigame = reward_ticket_minigame
    },
    setstatus_cooldown(state, status_cooldown) {
      state.status_cooldown = status_cooldown
    },
    settime_cooldown(state, time_cooldown) {
      state.time_cooldown = time_cooldown
    },
    setminigame(state, minigame) {
      state.minigame = minigame
    },
    setstatus_deposit_minigame(state, status_deposit_minigame) {
      state.status_deposit_minigame = status_deposit_minigame
    },
    setsocial_line(state, social_line) {
      state.social_line = social_line
    },

    setAPI() {
      this.state.API = 'https://' + this.state.IP + ':' + this.state.apiname
    },
    setapiname(state, apicode) {
      if (apicode == 11000) {
        state.apiname = '/member/register'
      } else if (apicode == 11001) {
        state.apiname = '/member/getprofileuser'
      } else if (apicode == 11002) {
        state.apiname = '/member/update-password'
      } else if (apicode == 11004) {
        state.apiname = '/member/getbanking'
      } else if (apicode == 11005) {
        state.apiname = '/member/login'
        // state.apiname = apicode + '/Login'
      } else if (apicode == 11006) {
        state.apiname = '/member/logoff'
      } else if (apicode == 11007) {
        state.apiname = '/member/withdraw'
      } else if (apicode == 11008) {
        state.apiname = '/member/captcha'
      } else if (apicode == 11009) {
        state.apiname = '/member/getbankdeposit'
      } else if (apicode == 11010) {
        state.apiname = '/member/getwithdrawamount'
      } else if (apicode == 11011) {
        state.apiname = '/member/getchannel'
      } else if (apicode == 11012) {
        state.apiname = '/member/getwithdrawhistory'
      } else if (apicode == 11013) {
        state.apiname = '/member/whitelist'
      } else if (apicode == 11014) {
        state.apiname = '/member/getdeposithistory'
      } else if (apicode == 11015) {
        state.apiname = '/member/updatedepositdoc'
      } else if (apicode == 11019) {
        state.apiname = '/member/getpromotion'
      } else if (apicode == 11020) {
        state.apiname = '/member/verifycoupon'
      } else if (apicode == 11021) {
        state.apiname = '/member/getpromotionmember'
      } else if (apicode == 11022) {
        state.apiname = '/member/selectpromotion'
      } else if (apicode == 11023) {
        state.apiname = '/member/checkin'
      } else if (apicode == 11024) {
        state.apiname = '/member/getcheckin'
      } else if (apicode == 11025) {
        state.apiname = '/member/validgame'
      } else if (apicode == 11026) {
        state.apiname = '/member/getnameref'
      } else if (apicode == 11027) {
        state.apiname = '/member/getwallet-commisson'
      } else if (apicode == 11028) {
        state.apiname = '/member/withdraw-commisson'
      } else if (apicode == 11029) {
        state.apiname = '/member/commisson-history'
      } else if (apicode == 11030) {
        state.apiname = '/member/check_status_bank'
      } else if (apicode == 11031) {
        state.apiname = '/member/getwinlosshistory'
      } else if (apicode == 12000) {
        state.apiname = '/SCB/scan-silp'
      } else if (apicode == 12001) {
        state.apiname = '/member/check-status-scan_qr'
      } else if (apicode == 12002) {
        state.apiname = '/member/get-landing-page'
      } else if (apicode == 12003) {
        state.apiname = '/member/get-login-page'
      } else if (apicode == 12004) {
        state.apiname = '/member/get-member-page'
      } else if (apicode == 12005) {
        state.apiname = '/member/start-time-normal'
      } else if (apicode == 12006) {
        state.apiname = '/member/start-time-game'
      } else if (apicode == 45003) {
        // state.apiname = '/provieder/getgamelist'
        state.apiname = '/game_setting/get-games'
      } else if (apicode == 45004) {
        state.apiname = '/provieder/startgame'
      } else if (apicode == 45005) {
        state.apiname = '/search/searchgame'
      } else if (apicode == 51001) {
        state.apiname = '/minigame_setting_member/get-setting-minigame'
      } else if (apicode == 51002) {
        state.apiname = '/minigame_setting_member/get-ticket-minigame'
      } else if (apicode == 51003) {
        state.apiname = '/minigame_setting_member/receive-byId-ticket-minigame'
      } else if (apicode == 51004) {
        state.apiname = '/minigame_setting_member/get-reward-minigame'
      } else if (apicode == 51005) {
        state.apiname = '/minigame_setting_member/history'
      } else if (apicode == 51006) {
        state.apiname = '/minigame_setting_member/used-ticket-minigame'
      } else if (apicode == 51007) {
        state.apiname = '/minigame_setting_member/insert-playlog'
      } else if (apicode == 51008) {
        state.apiname = '/minigame_setting_member/stack-turnover'
      } else if (apicode == 51009) {
        state.apiname = '/minigame_setting_member/get-ticket-minigame-turnover'
      } else if (apicode == 51010) {
        state.apiname =
          '/minigame_setting_member/receive-byId-ticket-turnover-minigame'
      }
    },
  },
  actions: {},
  modules: {},
})
